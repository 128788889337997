<template>
  <div class="my-account-login">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <clinic-menu></clinic-menu>
            <div class="column is-12 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Mis tarjetas</h3>
              <div class="columns is-variable is-3 is-flex-wrap-wrap">
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box smaller">
                    <p class="pb-4"><strong class="txt-yellow is-uppercase">Tarjeta principal</strong></p>
                    <p>
                      <strong>Mastercard que termina con 4899</strong><br>
                      Cad. 9/24<br>
                      John Doe<br><br>
                      <a href="#" class="is-underlined mr-2">Editar</a>
                      <a href="#" class="is-underlined mr-2">Eliminar</a>
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box smaller">
                    <p>
                      <strong>Mastercard que termina con 4899</strong><br>
                      Cad. 9/24<br>
                      John Doe<br><br>
                      <a href="#" class="is-underlined mr-2">Editar</a>
                      <a href="#" class="is-underlined mr-2">Eliminar</a>
                      <a href="#" class="is-underlined">Seleccionar como principal</a>
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box smaller is-empty">
                    <button><span class="icon-filters-open"></span></button>
                  </div>
                </div>
              </div>

              <h3 class="title is-4 pb-4 pt-4">Añadir una nueva tarjeta</h3>
              <div class="columns is-multiline">
                <!-- <div class="column is-12 mb-3">
                  <form id="payment-form" v-show="isAddPaymentVisible">
                    <div id="payment-element">
                    </div>
                    <button id="submit" class="action-btn mt-4">Añadir</button>
                    <div id="error-message">
                    </div>
                    <div id="message">
                    </div>
                  </form>
                </div>
                -->

                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>Tipo de tarjeta*</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Número de tarjeta*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre y Apellido*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="select is-primary is-full-width">
                    <select>
                      <option>Fecha de caducidad*</option>
                      <option>With options</option>
                    </select>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Código de seguridad (CVV)*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox">
                      Seleccionar como principal
                    </label>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Añadir tarjeta</button>
                </div>
              </div>

              <hr>

              <h3 class="title is-4 pb-4 pt-4">Mi cuenta SEPA</h3>
              <div class="columns is-variable is-3 is-flex-wrap-wrap">
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box smaller">
                    <p class="pb-4"><strong class="txt-yellow is-uppercase">SEPA principal</strong></p>
                    <p>
                      <strong>ES9121000418450200051332</strong><br>
                      Banco Santander<br>
                      John Smith<br><br>
                      <a href="#" class="is-underlined mr-2">Editar</a>
                      <a href="#" class="is-underlined mr-2">Eliminar</a>
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box smaller is-empty">
                    <button><span class="icon-filters-open"></span></button>
                  </div>
                </div>
              </div>

              <h3 class="title is-4 pb-4 pt-4">Añadir SEPA</h3>
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Código IBAN*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'BIC/SWIFT'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre del titular de la cuenta*'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3"></div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox">
                      Seleccionar como principal
                    </label>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Añadir tarjeta</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import ClinicMenu from '@/components/Clinic/ClinicMenu.vue'
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'ClinicCards',
  components: {
    ClinicMenu,
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      stripeInstance: null,
      isAddPaymentVisible: true,
      email: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      clinic: 'user/clinic'
    })
  },
  methods: {
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    initStripeElement () {
      const self = this
      this.$nextTick(() => {
        const options = {
          clientSecret: self.clinic.stripe_setup_intent_client_secret
          // Fully customizable with appearance API.
          // appearance: {/*...*/},
        }

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
        const elements = self.stripeInstance.elements(options)

        // Create and mount the Payment Element
        const paymentElement = elements.create('payment')
        paymentElement.mount('#payment-element')

        self.initStripeForm(elements)
      })
    },
    initStripeForm (elements) {
      const self = this
      const form = document.getElementById('payment-form')
      this.isFormInitialized = true
      form.addEventListener('submit', async (event) => {
        event.preventDefault()

        const { error } = await self.stripeInstance.confirmSetup({
          // `Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: window.location.origin + window.location.pathname
          }
        })

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          // const messageContainer = document.querySelector('#error-message')
          // messageContainer.textContent = error.message
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.message
            }
          })
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      })
    }
  },
  created () {
    this.stripeInstance = Stripe(process.env.VUE_APP_STRIPE_PK, {
      stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT_ID
    })
    // console.log(process.env.VUE_APP_STRIPE_PK)
  },
  mounted () {
    // this.initStripeElement()
  }
}
</script>

<style scoped lang="scss">
.my-account-login{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          .recap-order-box{
            min-height: 275px;
            border-radius: 8px;
            padding: 21px 28px;
            &.smaller{
              min-height: 205px;
            }
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
